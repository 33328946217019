<template>
  <div id="scholar">
    <div class="nav">
      <router-link to="/usermessage">基本信息</router-link>
      <router-link to="/userpersonal">个人履历</router-link>
      <router-link to="/useraward">获奖情况</router-link>
      <router-link to="/userpicture">学者图集</router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#scholar {
  min-height: 1068px;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 10px;
  padding: 40px;
  padding-top: 20px;
  .nav {
    height: 48px;
    border-bottom: 1px solid #e6e6e6;
    a {
      width: 125px;
      display: inline-block;
      text-align: center;
      line-height: 40px;
      font-size: 18px;
      color: #999999;
      &.router-link-exact-active::after {
        content: "";
        display: block;
        width: 125px;
        height: 8px;
        background-image: url("~@/assets/imgs/xz.png");
        background-size: 100% 100%;
      }
    }
    &::v-deep .router-link-exact-active {
      color: #00A4FF;
    }
  }
}
</style>
